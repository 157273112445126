import type { LinksFunction, LoaderFunctionArgs } from '@remix-run/node';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData
} from '@remix-run/react';
import { Theme, ThemeProvider, useTheme } from 'remix-themes';
import { cn } from '~/utils/cn';
import { Boundary } from './components/ui/error';
import TailwindCSS from './root.css?url';
import { HOST_URL } from './utils/http';
import { useNonce } from './utils/nonce-provider';
import { themeSessionResolver } from './utils/theme.server';

export const links: LinksFunction = () => {
  return [{ rel: 'stylesheet', href: TailwindCSS }];
};

export function ErrorBoundary() {
  const nonce = useNonce();

  return (
    <html>
      <head>
        <title>Oops!</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
        <Links />
      </head>
      <body className="h-screen bg-background text-center text-foreground">
        <div className="mx-auto flex max-w-[1000px] flex-col gap-10 p-10">
          <Boundary />
        </div>
        <Scripts nonce={nonce} />
      </body>
    </html>
  );
}

export async function loader({ request }: LoaderFunctionArgs) {
  const { getTheme } = await themeSessionResolver(request);
  const isMagicLink = request.url.startsWith(`${HOST_URL}/m/`);
  const isHome = request.url === HOST_URL;

  return {
    theme: isMagicLink || isHome ? Theme.LIGHT : getTheme()
  };
}

export default function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <ThemeProvider
      specifiedTheme={data?.theme || Theme.LIGHT}
      themeAction="/resources/set-theme">
      <App />
    </ThemeProvider>
  );
}

export function App() {
  const [theme] = useTheme();
  const nonce = useNonce();

  return (
    <html lang="en" className={cn(theme)}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
        <Links />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
      </body>
    </html>
  );
}
